import React from 'react'
import logo from '../../assets/logo.png'

const HomeFooter = () => {
    return (
        <section className='homefooter-section max-sm:px-[25px] pt-3'>
            <div className="homefooter-inner">
                <div className="homefooter-logo">
                    <img src={logo} alt="" />
                </div>
                <div className="copyright mt-4 text-center">
                    <p>Copyright © 2023 All rights reserved.</p>
                </div>
            </div>
        </section>
    )
}

export default HomeFooter
