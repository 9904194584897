// home page ecosystem image
import image1 from '../assets/homes/ethereum_ico.5cf7324a.svg'
import image2 from '../assets/homes/cronos.905354fe.svg'
import image3 from '../assets/homes/bnb-chain.1dc0d23e.svg'
import image4 from '../assets/homes/polygon.9d390282.svg'
import image5 from '../assets/homes/fantom.097d2095.svg'
import image6 from '../assets/homes/avalanche.2f718f8a.svg'
import image7 from '../assets/homes/optimism.9fbf71b4.svg'
import image8 from '../assets/homes/arbitrum.266eb93e.png'
import image9 from '../assets/homes/era.96346c16.svg'


// Invite rules page data
import intro1 from '../assets/main/intro0.266b801f.svg'
import intro2 from '../assets/main/intro1.5d93bf0b.svg'
import intro3 from '../assets/main/intro2.ff59de3e.svg'

// Mev address image
import bnb from '../assets/main/bnb.55b481c2.svg'
import eth from '../assets/main/eth.2b470564.svg'





// Home page how to make money data
export const hMoneyM = [
    {
        id: 1,
        para: '1. In the UNISWAP liquidity pool, Bob is a retail investor who wants to exchange $1000 of WETH for USDC. His transactions have been sent to the mempool, making him a victim of sandwich arbitrage. The transaction is marked ① in the image below.'
    },
    {
        id: 2,
        para: `2. Unfortunately, the sandwich robot AI, which has been scanning the mempool, detected Bob's swap transaction.`
    },

    {
        id: 3,
        para: `3. Ai makes a transaction to sell $650 of WETH and send it to the mempool. In the end, she gets 1,842,200 USDC at the exchange rate of 1 USD WETH to 2,834 USDC. The block’s miners accept this exchange first because the AI pays higher gas fees or miner tips. The exchange resulted in an exchange rate of 1 USD WETH to 2,821 USDC. This transaction and an AI sent after Bob are marked ② in the figure below.`
    },

    {
        id: 4,
        para: `4. Bob's transaction goes through the mempool and enters the block to sell $1000 of WETH for $2,821,000, he should be able to get 2,834,000 USDC.`
    },

    {
        id: 5,
        para: `5. The $1,842,200 USDC sold by AI passed the mempool and was recorded by the miners in the block. She receives $652.9 in WETH.`
    },
    {
        id: 6,
        para: `6. These three transactions are marked as ③ in the diagram below, which shows the order in which the miners accepted them.`
    },
]

// Home page FAQ data
export const faqData = [
    {
        id: 1,
        question: 'What Is Arbitrage',
        answer: `In economics and finance, arbitrage is the practice of exploiting price differences in two or more markets; a combination of matching trades is made to take advantage of the difference, and profit is the difference between the market prices of a unit traded. Simply put, it's an opportunity to make a risk-free profit after deducting transaction costs.`
    },
    {
        id: 2,
        question: 'The three pillars of DEFI',
        answer: `Structure, behavior and network are the invisible pillars of DeFi. Whale strictly analyzes asset transfers between liquidity pools, EOAs and protocols in an easy-to-understand way. Profit and gain from trading.`
    },
    {
        id: 3,
        question: 'What Liquidity pool',
        answer: `It is one of the key infrastructures of DeFi. Liquidity pools include a set of cryptoassets that can be used for decentralized trading, lending, and more. For example, liquidity pools are critical for automated market makers (AMMs), lending protocols, yield farming, synthetic assets, on-chain insurance, blockchain gaming, and more.`
    },
    {
        id: 4,
        question: 'What Is the liquidation threshold',
        answer: `Liquidation Threshold: is the percentage of collateral value that counts towards borrowing capacity. For example, an 80% liquidation threshold means that if the value rises above 80% of the collateral, the loan is undercollateralized and could be liquidated. The difference between the Loan-To-Value and the liquidation threshold is a safety buffer for the borrower.`
    },
]

// home page ecosystem all image
export const ecosystemData = [
    {
        id: 1,
        image: image1
    },
    {
        id: 2,
        image: image2
    },
    {
        id: 3,
        image: image3
    },
    {
        id: 4,
        image: image4
    },
    {
        id: 5,
        image: image5
    },
    {
        id: 6,
        image: image6
    },
    {
        id: 7,
        image: image7
    },
    {
        id: 8,
        image: image8
    },
    {
        id: 9,
        image: image9
    },
];




// Robot page table data
export const tableData =[
    {
        id: 1,
        name: 'Robot Type',
        detail: 'Sandwich'
    },
    {
        id: 2,
        name: 'Minimum Investment',
        detail: '0.25 WBNB'
    },
    {
        id: 3,
        name: 'Monthly Rate Of Return',
        detail: '40-55%'
    },
    {
        id: 4,
        name: 'Speed',
        detail: '0.5s'
    },
    {
        id: 5,
        name: 'Blockchain',
        detail: 'BSC'
    },
    {
        id: 6,
        name: 'Concurrency',
        detail: 'Unlimited'
    },
];




// Invite rules page data 
export const inviteprofitData = [
    {
        id: 1,
        image: intro1,
        title: 'Easy',
        para: 'Invite friends to join Whale platform and earn arbitrage income together'
    },
    {
        id: 2,
        image: intro2,
        title: 'Invitation reward',
        para: `Get a 5% bonus on your friend's arbitrage profits`
    },
    {
        id: 3,
        image: intro3,
        title: 'How to invite',
        para: 'Friends join Whale through your invitation link to get invitation rewards'
    },
]

// Top 10 Arbritrage Contracts data
export const contractsData = [
    {
        id: 1,
        title: 'Uniswap V3: Router 2',
        parsent: '44.7787%'
    },
    {
        id: 2,
        title: 'Uniswap V2: Router 2',
        parsent: '13.5676%'
    },
    {
        id: 3,
        title: 'Metamask: Swap Router',
        parsent: '7.1341%'
    },
    {
        id: 4,
        title: 'ZeroEx Proxy',
        parsent: '5.5049%'
    },
    {
        id: 5,
        title: '1inch v5: Router',
        parsent: '5.1546%'
    },
    {
        id: 6,
        title: '1inch v4: Router',
        parsent: '3.3919%'
    },
    {
        id: 7,
        title: '0x: Exchange Proxy',
        parsent: '3.1028%'
    },
    {
        id: 8,
        title: 'SushiSwap: Router',
        parsent: '2.4077%'
    },
    {
        id: 9,
        title: 'Paraswap v5: Augustus Swapper Mainnet',
        parsent: '1.8238%'
    },
    {
        id: 6,
        title: 'Shiba Inu: ShibaSwap',
        parsent: '1.2956%'
    },
]


// Mev address data
export const mevaddressData =[
    {
        id: 1,
        icon : bnb,
        userId: 'erttrt5yty...77f6ryyyr'
    },
    {
        id: 2,
        icon : bnb,
        userId: '0gotjjgigg...854uudttt'
    },
    {
        id: 3,
        icon : eth,
        userId: '0ogoutjjjf54...04o7dyenff'
    },
    {
        id: 4,
        icon : eth,
        userId: '069jfuyyff...24rwtyxyyde'
    },
    {
        id: 5,
        icon : eth,
        userId: '0iruhhhcc9...kfihryudds'
    },
   
]