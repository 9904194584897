import React from 'react'
import { Link } from 'react-router-dom'
import download from './../../assets/homes/download.png'
import bubble from '../../assets/homes/bubble-tail.cd66c570.svg'

const Hero = () => {
    return (
        <div className="hero-section pt-20">
            <div className="hero-header text-center">
                <h2>Welcome to <br /> <span className={'text-[#c4000f]'}>AppleBotX</span></h2>
                <h3>An era from Cefi to defi <br /> Welcome to the world of Lossless Trading</h3>
                <Link to={'/main'}><button>Start MEV</button></Link>
            </div>
            <div className="uni-view-main m-auto">
                <div className="uni-view-first relative">
                    <h3>$ 1,036,352.{163773}</h3>
                    <p className='pb-3'>MEV Accumulative Profit</p>
                    <h3>$ 2,056,400.000000</h3>
                    <p>Total investment in MEV</p>
                    <div className='absolute -bottom-[56px] left-[30%]'>
                        <img src={bubble} alt="" />
                    </div>
                </div>
                <div className="uni-view-second grid grid-cols-2 items-center relative">
                    <div className='absolute z-10 -top-5 left-[45%]'>
                        <img src={download} alt="" />
                    </div>
                    <Link><p className='!mt-8'>Certificate</p></Link>
                    <Link><p className='!mt-8'>MSB license</p></Link>
                    <Link><p className='!mb-5'>Bylaws</p></Link>
                    <Link><p className='!mb-5'>MEV Introduction</p></Link>
                </div>
            </div>
        </div>
    )
}

export default Hero
