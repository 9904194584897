import React, { useEffect, useState } from "react";
import "../../components/InviteFriend/style.css";
import { InviteHero, InviteProfits } from "../../components/InviteFriend";
import friend from "../../assets/main/empty.78da646d.png";
import Web3 from "web3";
import {
  MEVContractABI,
  USDTContract,
  USDTContractABI,
  MEVContract,
  DefaultNetwork,
  RPCURL
} from "../../contracts";
import {
  useAccount,
  useBalance,
  useContractReads,
  useContractWrite,
  useContractEvent,
  useNetwork,
  useSwitchNetwork,
  
} from "wagmi";
import moment from "moment";
import { ethers } from "ethers";
import TableData from "../../components/Robot/TableData";

const InvitePage = () => {
  const [page, setPage] = useState("robot");
  const { address } = useAccount();
  const [friendRefData, setFriendRefData] = useState([]);
  const web3 = new Web3(RPCURL);
  const { chain } = useNetwork();
/*   const listenEvent1 = async () => {
    //assets record
    if (address != undefined) {
     
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("Deposit", {
          fromBlock: 0,
          toBlock: "latest",
        })
        .then((events) => {
          console.log(events);
        });
    }
  };
 */
/*   const listenEvent2 = async () => {
    //assets record
    if (address != undefined) {
      
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("Withdrawal", {
          fromBlock: 0,
          toBlock: "latest",
        })
        .then((events) => {
          console.log(events);
        });
    }
  };

  const listenEvent3 = async () => {
    //total revenue
    if (address != undefined) {
     
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("ProfitDistributed", {
          fromBlock: 0,
          toBlock: "latest",
        })
        .then((events) => {
          console.log(events);
        });
    }
  }; */

  const getReferralBonusEvent = async () => {
    //my friends
    if (address != undefined) {
      
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("ReferralBonus", {
          fromBlock: 35579866,
          toBlock: "latest",
        })
        .then((events) => {
          setFriendRefData(events);
        });
    }
  };

  async function loadReferralBonusEvents() {
    const pairContract = new web3.eth.Contract(MEVContractABI, MEVContract);
    let startBlock = 35579866; /* Your start block number */
    const endBlockNumber = await web3.eth.getBlockNumber();
    const endBlock = Number(endBlockNumber); /* Your end block number */
    const maxBlocksPerRequest = 10000; // You can adjust this based on your requirements
    const events = [];

    const batches = [];
    while (startBlock <= endBlock) {
        const toBlock = Math.min(startBlock + maxBlocksPerRequest - 1, endBlock);
        batches.push({ fromBlock: startBlock, toBlock });
        startBlock = toBlock + 1;
    }

    const fetchBatchEvents = async ({ fromBlock, toBlock }) => {
        try {
            const batchEvents = await pairContract.getPastEvents('ReferralBonus', {
                fromBlock,
                toBlock,
            });
            return batchEvents;
        } catch (error) {
            console.error(`Error fetching events for blocks ${fromBlock} to ${toBlock}: ${error}`);
            return [];
        }
    };

    const eventBatches = await Promise.all(batches.map(fetchBatchEvents));
    return eventBatches.flat();
}

  useEffect(() => {
    // listenEvent1();
    // listenEvent2();
    // listenEvent3();
    if (chain !== undefined && chain.id === DefaultNetwork) {
      try {

        if (friendRefData.length === 0){
          loadReferralBonusEvents()
          .then(events => {
            setFriendRefData(events);
          })
          .catch(error => {
              console.error('Failed to load events:', error);
          });
        }
        
        //getReferralBonusEvent();
      } catch (error) {
        // handle/ignore error
      }
    }
   
  }, []) // empty array means only run on mount
  return (
    <div>
      <InviteHero />
      <div className="robot-invite-page text-white my-2 flex">
        <div className="first-btn ml-2">
          <button
              className={`${page === "robot" ? "robot-btn" : ""}`}
              onClick={() => setPage("robot")}
          >
            Invitation Rules
          </button>
          {page === "robot" ? <hr /> : ""}
        </div>
        <div className="second-btn">
          <button
              className={`${page === "robot" ? "" : "invite-btn"}`}
              onClick={() => setPage("invite")}
          >
            My Friends
          </button>
          {page === "invite" ? <hr /> : ""}
        </div>
      </div>
      <div className={'w-full px-3'}>
        <div
            className={`${
                page === "robot" ? "bg-white rounded-xl" : "bg-white rounded-xl"
            } pt-2`}
        >

          {page === "robot" ? (
              <InviteProfits />
          ) : (
              <div>
                {friendRefData.length == 0 ? (
                    <div className="h-[60vh] text-center">
                      <img
                          className="w-[160px] h-[160px] m-auto"
                          src={friend}
                          alt=""
                      />
                      <p className="text-[#979696]">No Data</p>
                    </div>
                ) : (
                    // Referral data show in Table
                    <div className="text-center m-3 mb-0 bg-white rounded-xl">
                      <div className="max-md:overflow-auto p-4 px-1 relative min-h-[400px]">
                        <TableData
                            data={friendRefData}
                            itemsPerPage={10}
                            tableType={"referralTable"}
                        />
                      </div>
                    </div>
                )}
              </div>
          )}
        </div>
      </div>

    </div>
  );
};

export default InvitePage;
