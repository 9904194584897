import React, { useEffect, useRef, useState } from 'react'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import copy from '../../assets/main/copy.png'
import { mevaddressData } from '../../Services/DataUtils';
import {
  MEVContractABI,
  USDTContract,
  USDTContractABI,
  MEVContract,
  DefaultNetwork,
  RPCURL
} from "../../contracts";
import { ethers, parseEther } from "ethers";
import {
  useAccount,
  useBalance,
  useContractReads,
  useContractWrite,
  useContractEvent,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import Web3, { providers } from "web3";
import moment from "moment";
import { Pagination } from "@mui/material";
import usePagination from "../../helper/Pagination";
const web3 = new Web3(RPCURL);
const LiveOrder = () => {
  const [value, setValue] = useState('');
  const [alert, setAlert] = useState(false);
  const textAreaRef = useRef(null);
  const [mevhash, setMevhash] = useState([]);
  const [currentPage, setPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const { chain } = useNetwork();

  function copyToClipboard(e) {
    setValue(textAreaRef.current.innerText);
    e.clipboard.writeText(value)
    setAlert(true)
  };


  async function loadSyncEvents() {

    const pairContract = new web3.eth.Contract(MEVContractABI, MEVContract);
    // Example usage:
    let startBlock = 35579866;/* Your start block number */
    var endBlockNumber = await web3.eth.getBlockNumber();
    console.log(endBlockNumber)
    let endBlock = Number(endBlockNumber);/* Your end block number */
    const maxBlocksPerRequest = 10000; // You can adjust this based on your requirements
    let events = [];
    while (startBlock <= endBlock) {
        let toBlock = Math.min(startBlock + maxBlocksPerRequest - 1, endBlock);
        console.log(toBlock)
        try {
            let batchEvents = await pairContract.getPastEvents('MEVTransactionRecorded', {
                fromBlock: startBlock,
                toBlock: toBlock,
            });
            events = events.concat(batchEvents);
            startBlock = toBlock + 1;
        } catch (error) {
            console.error(`Error fetching events for blocks ${startBlock} to ${toBlock}: ${error}`);
            if (maxBlocksPerRequest > 1) {
                // If error, try with fewer blocks
                maxBlocksPerRequest = Math.floor(maxBlocksPerRequest / 2);
            } else {
                // If already at minimum block range, log and skip ahead
                console.error(`Skipping blocks ${startBlock} to ${toBlock}`);
                startBlock = toBlock + 1;
            }
        }
    }
    return events;
}




  const getPastMevEvents = async () => {
    let fromBlock = 35579866;
    let toBlock = 35856136;
    let chunkLimit = 10000;
    //console.log("test")

    try {

      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      const blockNumber = await web3.eth.getBlockNumber();

      const fromBlockNumber = +fromBlock
      //console.log(fromBlockNumber)
   //   console.log(blockNumber+"contract")
      const toBlockNumber =
        toBlock === "latest" ? +Number(blockNumber) : +toBlock
      const totalBlocks = toBlockNumber - fromBlockNumber
      const chunks = []
    //  console.log(toBlockNumber)
      
      if (chunkLimit > 0 && totalBlocks > chunkLimit) {
        const count = Math.ceil(totalBlocks / chunkLimit)
        let startingBlock = fromBlockNumber

        for (let index = 0; index < count; index++) {
          const fromRangeBlock = startingBlock
          const toRangeBlock =
            index === count - 1 ? toBlockNumber : startingBlock + chunkLimit
          startingBlock = toRangeBlock + 1

          chunks.push({ fromBlock: fromRangeBlock, toBlock: toRangeBlock })
        }
      } else {
        chunks.push({ fromBlock: fromBlockNumber, toBlock: toBlockNumber })
      }
      //console.log(totalBlocks)
      const events = []
      //const errors = []
      for (const chunk of chunks) {
        console.log(chunk+"chunk")
        await contract.getPastEvents(
          "MEVTransactionRecorded",
          {
            fromBlock: chunk.fromBlock,
            toBlock: chunk.toBlock
          },
          ).then(chunkEvents => {
            if (chunkEvents?.length > 0) {
              events.push(...chunkEvents)
              //console.log(events+"chunkEvents")
            }
            
            //if (error) errors.push(error)
          });
      }
     // let arry = [];
    //  events.map((event) => {

       // arry.push({
       //   buyTxHash: event.returnValues.buyTxHash,
      //    mevBot: event.returnValues.mevBot,
      //    profit: event.returnValues.profit,
      //    sellTxHash: event.returnValues.sellTxHash,
       //   timestamp: moment(Number(event.returnValues?.timestamp)).format(
       //     "DD/MM/YYYY h:m:s A"
       //   ),
       // });
       
      //      console.log(event.returnValues.timestamp+"timestamp")
     // });
      setMevhash(events);
      console.log(JSON.stringify(events)+"all events")
      //console.log(errors)

      //return { events, errors, lastBlock: toBlockNumber }
    } catch (error) {
      //return { events: [], errors: [error], lastBlock: null }
    }
  }

  /* const getMAvData = async () => {
    try {
      let arry = [];
      
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      let mevTransactions_length = await contract.methods
        .getMevTransactionsCount()
        .call();
      for (let i = 0; i < mevTransactions_length; i++) {
        let mevTransactions = await contract.methods.mevTransactions(i).call();
         //console.log("mevTransactions",mevTransactions);
        arry.push({
          buyTxHash: mevTransactions.buyTxHash,
          mevBot: mevTransactions.mevBot,
          profit: mevTransactions.profit,
          sellTxHash: mevTransactions.sellTxHash,
          timestamp: moment(Number(mevTransactions?.timestamp)).format(
            "DD/MM/YYYY h:m:s A"
          ),
        });
      }
      setMevhash(arry);
    } catch (error) {
      //console.log(error);
    }
  }; */

  useEffect(() => {
  //  getPastMevEvents();
    /* loadSyncEvents()
    .then(events => {
        //console.log(`Loaded ${events.length} Sync events`);
        setMevhash(events);
        console.log(`Loaded ${JSON.stringify(events)} Sync events`);
        // Process events...
    })
    .catch(error => {
        console.error('Failed to load events:', error);
    }); */
    if (chain !== undefined && chain.id === DefaultNetwork) {
      try {
       
      } catch (error) {
        // handle/ignore error
      }
    }
   
  }, []) // empty array means only run on mount

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(false)
    }, 1000);
    return () => clearInterval(interval);
  })

  const count = Math.ceil(mevhash.length / itemsPerPage);
  const _DATA = usePagination(mevhash, itemsPerPage);
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
 // const classes = useStyles();
  return (
    <section className='liveorder-section relative'>
      <div className="liveorder-inner text-black bg-white py-3 px-6 shadow rounded-xl">
      {_DATA.currentData()?.map((item, ind) => {
          return (
            <div key={ind} className="liveorder-main">
              <div className="flex justify-between mb-2">
                {/* <p>11-07-2023 09:52:11 AM +UTC</p> */}
                <p>{moment(Number(item.returnValues?.timestamp)).format(
            "DD/MM/YYYY h:m:s A"
          )}</p>
                {/* <p className="text-[lime]">+0.138404</p> */}
                <p className="text-[lime]">{`+${parseFloat(ethers.formatEther(item.returnValues?.profit)).toFixed(3)}`}</p>
              </div>
              <div className="details flex justify-between items-center">
                <p className="flex space-x-2">
                  <span>MEV</span>
                  <span className="text-[#eaa800]">buy</span>
                  <span>Hash</span>
                  <span ref={textAreaRef} className="text-[#00aaff]">
                    <a
                      href={`https://bscscan.com/tx/${item.returnValues?.buyTxHash}`}
                      target="_blank"
                    >
                      {`${item.returnValues?.buyTxHash.substring(
                        0,
                        6
                      )}...${item.returnValues?.buyTxHash.substring(
                        item.returnValues?.buyTxHash.length - 4
                      )}`}
                    </a>
                  </span>
                </p>
                <img
                  onClick={() => copyToClipboard(navigator)}
                  className="w-[15px] h-[15px]"
                  src={copy}
                  alt=""
                />
              </div>
              <div className="details flex justify-between items-center">
                <p className="flex space-x-2">
                  <span>MEV</span>
                  <span className="text-[#a70000]">Sell</span>
                  <span>Hash</span>
                  <span ref={textAreaRef} className="text-[#00aaff]">
                    <a
                      href={`https://bscscan.com/tx/${item.returnValues?.sellTxHash}`}
                      target="_blank"
                    >
                      {`${item.returnValues?.sellTxHash.substring(
                        0,
                        6
                      )}...${item.returnValues?.sellTxHash.substring(
                        item.returnValues?.sellTxHash.length - 4
                      )}`}
                    </a>
                  </span>
                </p>
                <img
                  onClick={() => copyToClipboard(navigator)}
                  className="w-[15px] h-[15px]"
                  src={copy}
                  alt=""
                />
              </div>
              <div className="flex justify-between items-center">
                <div className="box-details flex items-center space-x-2">
                  <p>MEV address</p>
                  <img className="w-[12px] h-[12px]" src={item.icon} alt="" />
                  <p ref={textAreaRef} className="text-[#00aaff]">
                    <a
                      href={`https://bscscan.com/address/${item.returnValues?.mevBot}`}
                      target="_blank"
                    >
                      {`${item.returnValues?.mevBot.substring(
                        0,
                        6
                      )}...${item.returnValues?.mevBot.substring(item.returnValues?.mevBot.length - 4)}`}
                    </a>
                  </p>
                </div>
                <img
                  onClick={() => copyToClipboard(navigator)}
                  className="w-[15px] h-[15px]"
                  src={copy}
                  alt=""
                />
              </div>
              <hr className="my-4" />
            </div>
          );
        })}

        <div className="flex justify-center">
          <Pagination
              className='custom'
            sx={{button:{color: '#000000'}}}
            count={count}
            page={currentPage}
            onChange={handleChange}
          />
        </div>

        {alert ?
          <div className="absolute top-[0%] left-[30%] md:left-[40%] w-[200px] m-auto">
            <Stack sx={{ width: '200px' }} spacing={2}>
              <Alert severity="success">
                <AlertTitle>Copy Successfully</AlertTitle>
              </Alert>
            </Stack>
          </div> : ''
        }
      </div>
    </section>

  )
}

export default LiveOrder
