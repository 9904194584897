import React from 'react'
import hacken from '../../assets/homes/hacken.77702c47.webp'
import certex from '../../assets/homes/certik.8ad29022.webp'
import pick from '../../assets/homes/peck-shield.524be789.svg'
import { ecosystemData } from '../../Services/DataUtils'

const Security = () => {
    return (
        <div>
            <section className='security-section max-sm:px-[25px] pt-3'>
                <div className="security-section-header">
                    <h3 className='!text-center'>Security</h3>
                </div>
                <div className="security-main flex justify-center items-center m-auto space-x-2 mt-5">
                    <div className="img-box">
                        <img className='!w-[40px]' src={hacken} alt="" />
                    </div>
                    <div className="img-box">
                        <img src={certex} alt="" />
                    </div>
                    <div className="img-box">
                        <img src={pick} alt="" />
                    </div>
                </div>
            </section>
            <section className='security-section max-sm:px-[25px] pt-3'>
                <div className="security-section-header">
                    <h3 className='!text-center'>Ecosystem</h3>
                </div>
                <div className="security-main-eco security-main grid grid-cols-3 m-auto mt-5">
                    {ecosystemData.map((item) => {
                        return <div key={item.id} className="img-box m-2">
                            <img src={item.image} alt="" />
                        </div>
                    })}
                </div>
            </section>
        </div>
    )
}

export default Security
