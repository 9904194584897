import React from 'react'
import { inviteprofitData } from '../../Services/DataUtils'

const InviteProfits = () => {
    return (
        <section className='inviteprofit-section pb-10'>
            <div className="inviteprofit-inner text-black text-center px-2">
                <div className="inviteprofit-header">
                    <h2>Invite friends and share arbitrage profits</h2>
                    <p className='text-[14px] my-2'>Join Whale with your friends</p>
                   {/*  <p className='text-[16px] text-[#7e9aff]'>View invitation rules</p> */}
                    <button className='mt-14 text-white'>Invite Now</button>
                </div>
               {/*  <p className='text-start mt-36 font-semibold'>How to invite friends</p> */}
                {inviteprofitData.map((item) => {
                    return <div key={item.id} className="inviteprofit-box mt-5">
                        <div className="box-image m-auto">
                            <img className='m-auto w-[80px] h-[80px]' src={item.image} alt="" />
                        </div>
                        <h4 className='text-[17px] font-semibold'>{item.title}</h4>
                        <p className='text-[14px] text-[#72768f] mt-4'>{item.para}</p>
                    </div>
                })}
            </div>
        </section>
    )
}

export default InviteProfits
