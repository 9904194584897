import React from 'react'
import demo from '../../assets/homes/sandwich-demo.2c67d052.png'
import { hMoneyM } from '../../Services/DataUtils'
import referall from '../../assets/homes/referral-illustration.6be73a46.png'

const HowMoneyProccess = () => {
    return (
        <section className='howmoneyproccess-section max-sm:px-[25px]'>
            <div className="howmoneyproccess-header">
                <h3 className='leading-[1.3]'>How do AppleBotX make money in the process? Here is an example</h3>
            </div>
            <div className="howmoneyproccess-details">
                {hMoneyM.map((item) => {
                    return <p key={item.id}>{item.para}</p>
                })}
            </div>
            <div className="howmoneyproccess-image flex flex-col rounded-3xl bg-white backdrop-blur-2xl items-center gap-y-2 border border-white/50 shadow-2xl  p-8">
                <img className={'rounded-3xl shadow-2xl'} src={demo} alt="" />
                <p style={{color:'black'}}>The AI's income from the sandwich arbitrage is $2.9 WETH. The cost is the gas fee she reorders and the miner tip. Let's say it's 1.2 $WETH. In the end, AI's profit is 1.7 $WETH.</p>
            </div>
            <div className="coin-image-circle">
                <img src={referall} alt="" />
            </div>
        </section>
    )
}

export default HowMoneyProccess
