import React from 'react'

const Earnings = () => {
  return (
    <section className="earning-section pb-[62px]">
      <div className='earning-inner  text-black text-[14px] p-4 bg-white m-3 rounded-xl'>
        <div className="today-earning text-center mt-5">
          <p className='text-black mb-2'>Today's earnings</p>
          <p>$0.000000</p>
        </div>
        <div className="today-earning text-center mt-10">
          <p className='text-black mb-2'>Accumulated income</p>
          <p>$0.000000</p>
        </div>
        <p className='py-10'>The system distributes asset balance bonuses every day based on the remaining asset balance of each user = total account asset balance × 0.3%! </p>
      </div>
    </section>
  )
}

export default Earnings
