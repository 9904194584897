import React from 'react'
import best_price from '../../assets/homes/best-prices.0f2a08d0.webp'

const Revolution = () => {
    return (
        <section className='revolution-section max-sm:px-[25px]'>
            <div className="revolution-header">
                <h3>Revolutionizing decentralized financial <br /> transactions</h3>
                <p className='my-8 text-white text-[14px]'>Earn more profit than manual trading</p>
            </div>
            <div className="revolution-image w-[285px] m-auto">
                <img className='w-full' src={best_price} alt="" />
            </div>
        </section>
    )
}

export default Revolution
