import React, { useState } from 'react'
import Navbar from '../../components/Navbar'
import RobotPage from './RobotPage'
import InvitePage from './InvitePage'
import '../../components/Robot/style.css'

const MainPage = () => {
  const [page, setPage] = useState('robot')
  return (
    <div className='bg-[#f5f5f5] font-mono'>
      <Navbar main={'main'} />
      <div className="robot-invite-page pt-12 text-white flex">
        <div className="first-btn ml-2">
          <button className={`${page === 'robot' ? 'robot-btn' : ''}`} onClick={() => setPage('robot')}>Assets</button>
          {page === 'robot' ? <hr /> : ''}
        </div>
        <div className="second-btn">
          <button className={`${page === 'robot' ? '' : 'invite-btn'}`} onClick={() => setPage('invite')}>Invite Friends</button>
          {page === 'invite' ? <hr /> : ''}
        </div>
      </div>
      {page === 'robot' ?
        <RobotPage /> : <InvitePage />
      }
    </div>
  )
}

export default MainPage
