import { Modal, Switch } from "antd";
import React, { useEffect, useState } from "react";
import LiveOrder from "./LiveOrder";
import MevAddress from "./MevAddress";
import Contracts from "./Contracts";
import { ethers, parseEther } from "ethers";
import axios from "axios";
import Web3, { providers } from "web3";
import {
  MEVContractABI,
  USDTContract,
  USDTContractABI,
  MEVContract,
  DefaultNetwork,
  RPCURL
} from "../../contracts";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import {
  useAccount,
  useBalance,
  useContractReads,
  useContractWrite,
  useContractEvent,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";
const Overview = () => {
  const { chain } = useNetwork();
  const [page, setPage] = useState("live");
  //const [loading, setLoading] = useState(false);
  //const [open, setOpen] = useState(false);

  const { address, isConnecting, isDisconnected } = useAccount();
  const [robot, setRobot] = useState("0");
  const [profit, setProfit] = useState("0");
  const [totalDeposit, setTotalDeposit] = useState("0");
  const [balance, setBalance] = useState("0");
  const [myInvest, setMyInvest] = useState("");
  const [transactionCount, setTransactionCount] = useState("0");
  const [addressCount, setAddressCount] = useState("0");
  const web3 = new Web3(RPCURL);
    const getTransactionCount = async () => {
    const count = await axios.get(
      "https://api.bscscan.com/api?module=account&action=txlist&address="+MEVContract+"&startblock=0&endblock=99999999&page=1&offset=10000&sort='asc'&apikey=RS6FSIXII4QWWU7II8TIS6KBXMM6XRWEGV"
    );
    setTransactionCount(count.data.result.length);
  };
  const {
    data,
    isError,
    isLoading,
    isSuccess,
    refetch: refetchProjects,
  } = useContractReads({
    contracts: [
      {
        address: MEVContract,
        abi: MEVContractABI,
        functionName: "depositors",
        args: [address],
        chainId: DefaultNetwork,
      },
      {
        address: MEVContract,
        abi: MEVContractABI,
        functionName: "getContractBalance",
        chainId: DefaultNetwork,
      },
      {
        address: MEVContract,
        abi: MEVContractABI,
        functionName: "getTotalDepositorAddresses",
        chainId: DefaultNetwork,
      },
    ],
    onSuccess(data) {
     // if (address != undefined && data != undefined) {
      
        setTotalDeposit(ethers.formatEther(data[1].result));
        setAddressCount(String(data[2].result));
        if (address != undefined && data != undefined) {
          var my = ethers.formatEther(data[0].result[0]);
          var total = ethers.formatEther(data[1].result);
          var value = parseFloat((my / total) * 100);
          setBalance(ethers.formatEther(data[0].result[0]));
          setMyInvest(value);
          setRobot(data[0].result[2]);
      }

      
      
     // 
    },
    onerror(error) {
      console.log(error);
    },
  });
/*   const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  }; */

/*   const handleItemClick = (itemId) => {
    setState(itemId);
  };
  const items = [
    { id: 1, text: "100" },
    { id: 2, text: "200" },
    { id: 3, text: "500" },
  ]; */
  
  const profitDistributedEvent = async () => {
    //if (address !== undefined) {
      var value2 = 0;
      const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
      await contract
        .getPastEvents("ProfitDistributed", {
          fromBlock: 35579866,
          toBlock: "latest",
        })
        .then((events) => {
          events.map((event) => {
            value2 += parseInt(ethers.formatEther(event.returnValues.profit));
          });
        });
      setProfit(ethers.parseEther(String(value2)));
   // }
  };

  async function loadProfitDistributedEvents() {

    const pairContract = new web3.eth.Contract(MEVContractABI, MEVContract);
    // Example usage:
    let startBlock = 35579866;/* Your start block number */
    var endBlockNumber = await web3.eth.getBlockNumber();
    console.log(endBlockNumber)
    let endBlock = Number(endBlockNumber);/* Your end block number */
    const maxBlocksPerRequest = 10000; // You can adjust this based on your requirements
    let events = [];
    while (startBlock <= endBlock) {
        let toBlock = Math.min(startBlock + maxBlocksPerRequest - 1, endBlock);
        console.log(toBlock)
        try {
            let batchEvents = await pairContract.getPastEvents('ProfitDistributed', {
                fromBlock: startBlock,
                toBlock: toBlock,
            });
            events = events.concat(batchEvents);
            startBlock = toBlock + 1;
        } catch (error) {
            console.error(`Error fetching events for blocks ${startBlock} to ${toBlock}: ${error}`);
            if (maxBlocksPerRequest > 1) {
                // If error, try with fewer blocks
                maxBlocksPerRequest = Math.floor(maxBlocksPerRequest / 2);
            } else {
                // If already at minimum block range, log and skip ahead
                console.error(`Skipping blocks ${startBlock} to ${toBlock}`);
                startBlock = toBlock + 1;
            }
        }
    }
    return events;
}


  useEffect(() => {
    getTransactionCount();
    //profitDistributedEvent();
    loadProfitDistributedEvents()
    .then(events => {
      var value2 = 0;
        //console.log(`Loaded ${events.length} Sync events`);
        events.map((event) => {
          value2 += parseInt(ethers.formatEther(event.returnValues.profit));
        });

      setProfit(ethers.parseEther(String(value2)));
        console.log(`Loaded ${JSON.stringify(events)} Sync events`);
        // Process events...
    })
    .catch(error => {
        console.error('Failed to load events:', error);
    });
    refetchProjects?.();
    if (chain !== undefined && chain.id === DefaultNetwork) {
      
      try {
       
      } catch (error) {
        // handle/ignore error
      }
        
        /* const interval = setInterval(() =>
        refetchProjects?.()
        , 1000);
        return () => clearInterval(interval); */
      
    }
  }, []) // empty array means only run on mount
  return (
    <section className="overview-section">
      <div className="overview-inner text-black/80 p-4 bg-white m-3 mt-0 rounded-xl">
        <div className="overview-header flex justify-between mb-4">
          <h3 className="text-black text-[18px]">Overview</h3>
          {/*  <div className="overview-head-btn flex space-x-2 bg-[#333a47] px-3 rounded-md">
            <p onClick={()=>setCount('24h')} className={`${count==='24h'?'text-white':''}`}>24H</p>
            <p onClick={()=>setCount('7d')} className={`${count==='7d'?'text-white':''}`}>7D</p>
            <p onClick={()=>setCount('30d')} className={`${count==='30d'?'text-white':''}`}>30D</p>
          </div> */}
        </div>
        <div className="same-line flex justify-between">
          <p>Number Of Addresses</p>
          <p className="text-black">{addressCount}</p>
        </div>
        <div className="same-line flex justify-between">
          <p>Profit</p>
          <p className="text-black">
            {parseFloat(ethers.formatEther(String(profit))).toFixed(3)} WBNB
          </p>
        </div>
        <div className="same-line flex justify-between">
          <p>Number Of Transactions</p>
          <p className="text-[black]">{String(transactionCount)}</p>
        </div>

        <hr className="my-2" />

        <div className="same-line flex justify-between">
          <p>
            Total Capital Pool<span className="text-[12px]">(WBNB)</span>
          </p>
          <p>
            My Investment<span className="text-[12px]">(WBNB)</span>
          </p>
        </div>
        <div className="same-line text-black flex justify-between">
          <p>{parseFloat(totalDeposit).toFixed(3)}</p>
          <p>
            {parseFloat(balance).toFixed(3)} {"(" + myInvest + "%" + ")"}
          </p>
        </div>

        <div className="same-line text-black flex justify-between mt-3">
          <p>My Robot Id</p>
          <p>{String(robot)}</p>
        </div>
        <div className="same-line text-black flex justify-between">
          <p>Rate of return</p>
          <p>0.000</p>
        </div>

        {/*  <div className="overview-footer-btn mt-4">
          <button onClick={showModal} className='bg-[#03afed]'>Start</button>
          <button onClick={() => showModal('para')} className='ml-3 bg-[#333a47]'>Cancel</button>
           <button className='ml-3 bg-[#216f4d]'>Provide liquidity</button> 
        </div> */}
      </div>
      {/*  <Modal className='modal-recharge' open={open} onOk={handleOk} onCancel={handleCancel} >
        <div className="modal-inner modal-inner-second text-center">
          <h3>{cancel === 'para' ? 'Cancel' : 'Start'}</h3>
          <hr />
          {cancel === 'para' ?
            <p className='mt-14 font-semibold mb-5'>Are you sure you want to cancel this bot?</p> :
            <form action="#" method='get' className='p-4 bg-[#2d2e3f66] rounded-xl mt-8'>
              <p className='text-start'>Participation Quantity</p>
              <div className="select-quantity flex justify-between items-center px-3">
                {items.map((item) => (
                  <p key={item.id} className={state === item.id ? 'active' : ''} onClick={() => handleItemClick(item.id)} > {item.text} </p>
                ))}
              </div>
              <input className='px-3' type="number" placeholder='Please enter quantity' />
              <p className='integer'>Please enter an integer multiple of 100!</p>
            </form>
          }
          <button style={{ backgroundImage: 'linear-gradient(93.96deg,#e05fbb .76%,#4250f4 100.57%)' }} className='w-[90%] mx-auto my-10'>Confirm</button>
        </div>
      </Modal> */}

      <div className="robot-invite-page pt-2 text-white flex">
        <div className="first-btn ml-2">
          <button
            className={`${page === "live" ? "robot-btn" : ""}`}
            onClick={() => setPage("live")}
          >
            Live MEV Order
          </button>
          {page === "live" ? <hr /> : ""}
        </div>
        {/*   <div className="second-btn">
          <button className={`${page === 'mevs' ? 'invite-btn' : ''}`} onClick={() => setPage('mevs')}>MEV address</button>
          {page === 'mevs' ? <hr /> : ''}
        </div>
        <div className="third-btn">
         <button className={`${page === 'contract' ? 'rule-btn' : ''}`} onClick={() => setPage('contract')}>Top 10 Arbritrage Contracts</button>
          {page === 'contract' ? <hr /> : ''}
        </div> */}
      </div>
      {page === "live" ? <LiveOrder /> : ""}
      {page === "mevs" ? <MevAddress /> : ""}
      {/* {page === 'contract'?
          <Contracts />:''
      } */}
      <ToastContainer />
    </section>
  );
};

export default Overview;
