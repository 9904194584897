import React, { useEffect, useRef, useState } from 'react'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import copy from '../../assets/main/copy.png'
import { mevaddressData } from '../../Services/DataUtils'

const MevAddress = () => {
  const [alert, setAlert] = useState(false);
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    setAlert(true)
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setAlert(false)
    }, 1000);
    return () => clearInterval(interval);
  })

  return (
    <section className='mevaddress-section relative'>
      <div className="mevaddress-inner text-black bg-white p-3 rounded-xl h-[60vh]">
        {mevaddressData.map((item) => {
          return <div key={item.id} className="flex justify-between items-center">
            <div className='flex items-center'>
              <img className='w-[12px] h-[12px] mr-2' src={item.icon} alt="" />
              <p ref={textAreaRef}>{item.userId}</p>
            </div>
            <img onClick={() => copyToClipboard(navigator)} src={copy} alt="copy" className='w-[15px] h-[15px]' />
          </div>
        })}
        {alert ?
          <div className="absolute top-[20%] left-[30%] md:left-[40%] w-[200px] m-auto">
            <Stack sx={{ width: '200px' }} spacing={2}>
              <Alert severity="success">
                <AlertTitle>Copy Successfully</AlertTitle>
              </Alert>
            </Stack>
          </div> : ''
        }
      </div>
    </section>
  )
}

export default MevAddress
