import React from 'react'

const SandwichArbitrage = () => {
    return (
        <section className='sandwich-arbitrage-section max-sm:px-[25px]'>
            <div className="sandwich-arbitrage-header">
                <h3>Sandwich Arbitrage Robot</h3>
                <p className='my-7'>Sandwich arbitrage exists because users have to send expected transactions to the blockchain’s mempool, the waiting area for transactions that have not yet been put into a block and need confirmation from block miners.</p>
            </div>
            <div className="sandwich-arbitrage-box">
                <div className="box">
                    <p>If a user sets a high slippage for a trade, the bot can take advantage of this opportunity by:</p>
                    <p className='my-3'>1. The first transaction of the robot sets higher gas and miner fees than the user in order to be accepted by the miners of the block earlier.</p>
                    <p>2. The bot will then send another transaction with the same or lower gas fee to ensure that the miner accepts the transaction later than the user, and the user's transaction will be squeezed by the bot's transaction.</p>
                </div>
            </div>
        </section>
    )
}

export default SandwichArbitrage
