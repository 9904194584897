import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Routers from './Routers/Routers'
import { createWeb3Modal, defaultWagmiConfig } from '@web3modal/wagmi/react'

import { WagmiConfig } from 'wagmi'
import { goerli, polygon, polygonMumbai,bsc } from 'viem/chains'

// 1. Get projectId at https://cloud.walletconnect.com
const projectId = 'eabecdc5e3721e45590cb52aba649488'

// 2. Create wagmiConfig
const metadata = {
  name: 'Web3Modal',
  description: 'Web3Modal Example',
  url: 'https://web3modal.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [bsc]
const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata })

// 3. Create modal
createWeb3Modal({ wagmiConfig, projectId, chains })
const App = () => {
  return (
    <WagmiConfig config={wagmiConfig}>
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
    </WagmiConfig>
  )
}

export default App
