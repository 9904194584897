import React from 'react'
import what_icon from '../../assets/homes/what-icon.png'
import money_in from '../../assets/homes/inmoney.be8d4e8a.svg'
import { faqData } from '../../Services/DataUtils'

const Faq = () => {
    return (
        <section className='faq-section max-sm:px-[25px]'>
            <div className="faq-section-header">
                <h3 className='!text-center'>FAQ</h3>
            </div>
            <div className="faq-main">
                {faqData.map((item) => {
                    return <div key={item.id} className="faq-main-question flex mt-5">
                        <div className="question-icon block">
                            <img className='w-[23px]' src={what_icon} alt="" />
                        </div>
                        <div className="question-answer ml-3 w-[100%]">
                            <h4>{item.question}</h4>
                            <p>{item.answer}</p>
                        </div>
                    </div>
                })}

            </div>
            <div className="faq-image flex flex-col rounded-3xl bg-white backdrop-blur-2xl border border-white/50 shadow-2xl items-center gap-y-2  p-8 text-black mt-14">
                <img className='m-auto rounded-3xl shadow-2xl' src={money_in} alt="" />
                <p className='mt-3 text-center'>What would you do if you had a fixed income that was automatically sent to your wallet every month?</p>
                <p className=' mt-16'>Making money should be easy, we build bridges to bring you risk-free trading returns with one click.</p>
            </div>
        </section>
    )
}

export default Faq
