import React from 'react'
import cash_back from '../../assets/homes/cash-back.0e08516d.webp'

const About = () => {
  return (
    <section className="about-whale-section max-sm:px-[25px]">
        <div className="about-whale-header flex items-center">
            <h3>About Whale</h3>
        </div>
        <div className="about-whale-para">
            <p>Whale is a platform focused on technology priority, security and decentralization, aiming to promote the frontier of intelligent 2.0 transactions in the DeFi field through innovative scalability. Help customers free their hands and enjoy income after sleep.</p>
        </div>
        <div className="about-whale-image w-[285px] m-auto pt-[30px]">
            <img className='w-full' src={cash_back} alt="" />
        </div>
    </section>
  )
}

export default About
