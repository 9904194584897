import moment from "moment";
import React, { useState } from "react";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

const TableData = ({ data, itemsPerPage, tableType }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

/*   const renderPageNumbers = () => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <span
          key={i}
          onClick={() => paginate(i)}
          className={currentPage === i ? "active" : ""}
        >
          {i}
        </span>
      );
    }
    return pageNumbers;
  }; */

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  let currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  currentItems = currentItems.reverse();

  return (
    <div>
      {tableType === "depositTable" ? (
        <table className="table-data w-full">
          <thead>
            <tr>
              <td>Date and Time</td>
              {/* <td>Status</td> */}
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((userData, ind) => {
              // Convert Timestamp
              const timestamp = parseFloat(userData.returnValues.timestamp);
              const momentObject = moment(timestamp * 1000);
              const formattedDateTime = momentObject.format("MM/DD/YYYY h:mm A");

              // Convert Amount with 18 decimals
              const ethers = require("ethers");
              const amount = parseFloat(
                userData.returnValues.amount
              ).toString();
              const formatAmount = ethers.formatEther(amount);

              // Return Values
              return (
                <tr key={ind}>
                  <td>{formattedDateTime}</td>
                  {/* <td>Deposit</td> */}
                  <td>{formatAmount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}
      {tableType === "withdrawTable" ? (
        <table className="table-data w-full">
          <thead>
            <tr>
              <td>Date and Time</td>
              {/* <td>Status</td> */}
              <td>Amount</td>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((userData, ind) => {
              // Convert Timestamp
              const timestamp = parseFloat(userData.returnValues.timestamp);
              const momentObject = moment(timestamp * 1000);
              const formattedDateTime =
                momentObject.format("MM/DD/YYYY h:mm A");

              // Convert Amount with 18 decimals
              const ethers = require("ethers");
              const amount = parseFloat(
                userData.returnValues.amount
              ).toString();
              const formatAmount = ethers.formatEther(amount);

              // Return Values
              return (
                <tr key={ind}>
                  <td>{formattedDateTime}</td>
                  {/* <td>Withdraw</td> */}
                  <td>{formatAmount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {tableType === "totalRevenueTable" ? (
        <table className="w-full table-data">
          <thead>
            <tr className="my-2">
              <th className="w-[33.33%]">Date and Time</th>
              <th className="w-[33.33%]">My Profit (WBNB)</th>
              <th className="w-[33.33%]">Bot Profit (WBNB)</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((revenue, ind) => {
              // Convert Timestamp
              const timestamp = parseFloat(revenue.returnValues.timestamp);
              const momentObject = moment(timestamp * 1000);
              const formattedDateTime =
                momentObject.format("MM/DD/YYYY h:mm A");

              // Convert Amount with 18 decimals
              const ethers = require("ethers");
              const amount = parseFloat(ethers.formatEther(revenue.returnValues.profit)).toFixed(12);
              const formatAmount = amount;

              // Return Values
              return (
                <tr key={ind} className="my-2">
                  <td>{formattedDateTime}</td>
                  <td>{parseFloat(ethers.formatEther(revenue.returnValues.share)).toFixed(12)}</td>
                  <td>{formatAmount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {tableType === "referralTable" ? (
        <table className="w-full">
          <thead className="pb-5">
            <tr className="text-black font-semibold">
              <td>Date and Time</td>
              <td className=" md:pr-14 lg:pr-[88px]">Referral</td>
              <td>Amount (WBNB)</td>
            </tr>
          </thead>
          <tbody className="mt-5">
            {currentItems.map((friend, ind) => {
              // Convert Timestamp
              const timestamp = parseFloat(friend.returnValues.timestamp);
              const momentObject = moment(timestamp * 1000);
              const formattedDateTime =
                momentObject.format("MM/DD/YYYY h:mm A");

              // Convert Amount with 18 decimals
              const ethers = require("ethers");
              const bonusAmount = parseFloat(
                friend.returnValues.bonusAmount
              ).toString();
              const formatBonusAmount = ethers.formatEther(bonusAmount);

              // Return Value
              return (
                <tr key={ind} className="text-black">
                  <td className="max-md:min-w-[152px]">{formattedDateTime}</td>
                  <td className="px-3 md:pr-14 lg:pr-[88px]">{friend.returnValues.referral}</td>
                  <td>{formatBonusAmount}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        ""
      )}

      {/* Pagination buttons */}
      <div className="pagination-table flex justify-center items-center space-x-2 absolute bottom-3 w-full left-0">
        <div
          onClick={prevPage}
          disabled={currentPage === 1}
          className="text-[#999] cursor-pointer"
        >
          <MdArrowBackIos />
        </div>
        <p className="w-[30px] h-[30px] rounded-full bg-[#c4000f] text-white flex justify-center items-center font-semibold text-[16px]">
          {currentPage}
        </p>
        <div
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className="text-[#999] cursor-pointer"
        >
          <MdArrowForwardIos />
        </div>
      </div>
    </div>
  );
};

export default TableData;
