import React from 'react'
import { tableData } from '../../Services/DataUtils'

const Rule = () => {
  return (
    <section className='rule-section px-3'>
      <div className="rule-inner bg-white p-4 rounded-xl">
        <div className="rule-table">
          <table className='w-full'>
            {tableData.map((item) => {
              return <tr key={item.id}>
                <td>{item.name}</td>
                <td>{item.detail}</td>
              </tr>
            })}
          </table>
        </div>
        <div className="rule-details text-[14px] text-black font-[400] mt-3 pb-14">
          <p>𝟏.The arbitrage profit will be distributed to the investment users in real time for each transaction, of which 40% of the profit of each transaction is the platform profit, and 60% is distributed to the investors in proportion</p>
          <p>𝟐.Invest users to start the robot to participate in the next profit distribution</p>
          <p>𝟑 .User dividend calculation formula: arbitrage profit × 60% × personal investment ratio(Proportion of personal investment = personal investment amount ÷ total funds of the current robot ID)</p>
          <div>
            <p>𝟒.Other instructions:</p>
            <p>① Number of addresses: the total number of wallet addresses invested in the entire network;</p>
            <p>② Profit: Displayed as the current machine 𝐈𝐃 arbitrage profit</p>
            <p>③ Total capital pool: Refers to the total amount of WBNB invested by participants in the entire network</p>
            <p>④ After each arbitrage robot reaches the maximum capital carrying capacity, the system will start a new robot, and the Whale system will intelligently allocate the capital investment ID, so that the investment amount of the same customer is in the same robot ID system.</p>
          </div>
          <p>5. Liquidity: Customers can also choose to provide liquidity for the sandwich robot, and the liquidity income is 50% of the real investment income</p>
        </div>
      </div>
    </section>
  )
}

export default Rule
