import React, { useEffect, useState } from 'react'
import { Earnings, MyAssets, Overview, Rule } from '../../components/Robot'

const RobotPage = () => {
  const [page, setPage] = useState('robot')
  return (
    <div>
      <MyAssets />
      <div className="robot-invite-page pt-2 pb-4 text-white flex">
        <div className="first-btn ml-2">
          <button className={`${page === 'robot' ? 'robot-btn' : ''}`} onClick={() => setPage('robot')}>Sandwich Robot</button>
          {page === 'robot' ? <hr /> : ''}
        </div>
      {/*   <div className="second-btn">
          <button className={`${page === 'balance' ? 'invite-btn' : ''}`} onClick={() => setPage('balance')}>Asset balance bonus</button>
          {page === 'balance' ? <hr /> : ''}
        </div> */}
        <div className="third-btn">
          <button className={`${page === 'rule' ? 'rule-btn' : ''}`} onClick={() => setPage('rule')}>Rule</button>
          {page === 'rule' ? <hr /> : ''}
        </div>
      </div>
      {page==='robot'?
        <Overview  />:''
      }
      {
        page==='balance'?
        <Earnings />:''
      }
      {
        page==='rule'?
        <Rule />:''
      }
    </div>
  )
}

export default RobotPage
