import React, { useEffect } from 'react'
import logo from '../assets/logo.png'
import erc from '../assets/homes/erc.84c3003d.png'
import dimensions from '../assets/homes/dimensions.97e27d15.png'
import cuntry from '../assets/homes/country.b9b3171c.png'
import { Link } from 'react-router-dom'
import { RxCross2 } from 'react-icons/rx';
import { useAccount } from 'wagmi';
import { useWeb3Modal } from '@web3modal/wagmi/react'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
const Navbar = (props) => {
    const { address, isConnecting, isDisconnected } = useAccount();
    const { open, close } = useWeb3Modal()
    return (
        <nav className='fixed w-full z-50'>
            <div className={`simgle-nav ${props.main === 'main' ? 'bg-black' : 'bg-black'}`}>
                <div className="navbar-inner flex justify-between items-center px-3 py-1">
                    <div className="nav-logo">
                        <div className="logo-image flex items-center">
                            <img className='h-[35px]' src={logo} alt="" />
                        </div>
                    </div>
                    <div className="nav-account-lang flex items-center">
                        <div className="account flex items-center" onClick={()=>open()}>
                           {/*  <img className='w-[20px] h-[20px]' src={erc} alt="" /> */}
                            <button className='text-black text-[14px] ml-1'>{address == undefined ? ' Connect ': address}</button>
                        </div>
                       {/*  <div className="lang">
                            <img className='w-[25px] h-[25px]' src={props.main==='main'? cuntry : dimensions} alt="language" />
                        </div> */}
                        {props.main === 'main' ?
                            <div className="lang ml-2">
                                <Link className='text-white text-[28px]' to={'/'}><RxCross2 /></Link>
                            </div> : ''
                        }
                    </div>
                </div>
            </div>
 <ToastContainer autoClose={5000} />
        </nav>
    )
}

export default Navbar