import React, { useEffect, useRef, useState } from 'react'
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from "web3";
import {
    MEVContractABI,
    USDTContract,
    USDTContractABI,
    MEVContract,
    DefaultNetwork,
    RPCURL
  } from "../../contracts";
  import {
    useAccount,
    useBalance,
    useContractReads,
    useContractWrite,
    useContractEvent,
    useNetwork,
    useSwitchNetwork,
  } from "wagmi";
const InviteHero = () => {
    const {address} = useAccount()
    const [value, setValue] = useState('');
    const [alert, setAlert] = useState(false);
    const [referralBonues, setreferralBonues] = useState('0')
    const textAreaRef = useRef(null);
    const web3 = new Web3(RPCURL);
    const origin = window.location.origin;
    const { chain } = useNetwork();

    function copyToClipboard(e) {
        setValue(textAreaRef.current.innerText);
        e.clipboard.writeText(value)
        setAlert(true)
    };

    const getReferralBonuses = async () => {
        //try {
           
            const contract = new web3.eth.Contract(MEVContractABI, MEVContract);
            let ReferralBonuses = await contract.methods.referralBonuses(address).call();
            setreferralBonues(ReferralBonuses)
      //  } catch (error) {
       //   console.log(error);
       // }
      };

    useEffect(() => {
        const interval = setInterval(() => {
            setAlert(false)
        }, 1000);

        if (chain !== undefined && chain.id === DefaultNetwork) {
      
            try {
                if(address){
                    getReferralBonuses()
                }
            } catch (error) {
              // handle/ignore error
            }
              
              /* const interval = setInterval(() =>
              refetchProjects?.()
              , 1000);
              return () => clearInterval(interval); */
            
        }
        
        return () => clearInterval(interval);
    })
    return (
        <section className='invitehero-section relative'>
            <div className="invitehero-inner p-4 bg-white m-3 rounded-xl text-white">
                <div className="invitehero-header text-center">
                    <h2>My Invitation Reward</h2>
                    <h3>${parseFloat(web3.utils.fromWei(referralBonues.toString(), "ether")).toFixed(3)}</h3>
                </div>
                <div className="invitation-link mt-8">
                    <div className="link-inner sm:flex justify-between">
                        <div className="link-left">
                            <p className='text-[#72768f] text-[14px]'>Invitation Link:</p>
                            <p ref={textAreaRef}>{origin}?referral={ address == undefined ? '':address}</p>
                        </div>
                        {alert ?
                            <div className="absolute top-[50%] left-[30%] md:left-[40%] w-[200px] m-auto">
                                <Stack sx={{ width: '200px' }} spacing={2}>
                                    <Alert severity="success">
                                        <AlertTitle>Copy Successfully</AlertTitle>
                                    </Alert>
                                </Stack>
                            </div> : ''
                        }
                        <div className="link-right">
                            <button onClick={() => copyToClipboard(navigator)}>Copy</button>
                        </div>
                    </div>
                    <hr className='mt-3' />
                </div>
            </div>
        </section>
    )
}

export default InviteHero
