import React from 'react'
import '../../components/Home/style.css'
import { About, Faq, Hero, HomeFooter, HowMoneyProccess, Revolution, SandwichArbitrage, Security, Trading } from '../../components/Home'
import Navbar from '../../components/Navbar'

const HomePage = () => {
  return (
    <div className='bg-[#f5f5f5] font-mono home-page overflow-auto'>
        <Navbar />
        <Hero />
        <About />
        <Trading />
        <Revolution />
        <SandwichArbitrage />
        <HowMoneyProccess />
        <Faq />
        <Security />
        <HomeFooter />
    </div>
  )
}

export default HomePage
