import React from 'react'
import trading from '../../assets/homes/kline_1_en.db2a525f.gif'

const Trading = () => {
  return (
    <section className='trading-section max-sm:px-[25px]'>
      <div className="trading-header">
            <h3>Time Freedom <br />Earn Money While You Sleep</h3>
            <p>24/7 Automated tTrading, Free Hands And Brains</p>
        </div>
        <div className="trading-image flex flex-col rounded-3xl bg-white backdrop-blur-2xl items-center gap-y-2 border border-white/50 shadow-2xl  p-8">
            <img src={trading} alt="" />
            <div className="trading-footer text-center mt-4">
                <div className='flex justify-center items-center space-x-28'>
                    <span>$1200billion+</span>
                    <span>40billion+</span>
                </div>
                <p className='text-[17px] text-black mt-4'>DeFi total market valueDeFi daily trading volume</p>
            </div>
        </div>
    </section>
  )
}

export default Trading
